import React from "react";
import { useSiteMetadata } from "../hooks/use-site-metadata";

/**
 * A SEO component that injects opengraph metadata, used by services like Linkedin or Facebook to
 * render previews.
 *
 * Linkedin's [post-inspector](https://www.linkedin.com/post-inspector/) can be used to verify that
 * previews are rendered properly, and to debug possible problems with them.
 */
export const SEO = ({
  title,
  description,
  image,
  pathname,
  author,
  keywords,
  children,
}) => {
  const {
    title: defaultTitle,
    description: defaultDescription,
    image: defaultImage,
    siteUrl,
    author: defaultAuthor,
    keywords: defaultKeywords,
  } = useSiteMetadata();

  const seo = {
    title: title || defaultTitle,
    description: description || defaultDescription,
    image: image ? `${siteUrl}${image}` : `${siteUrl}${defaultImage}`,
    url: `${siteUrl}${pathname || ``}`,
    author: author || defaultAuthor,
    keywords: keywords || defaultKeywords,
  };

  return (
    <>
      <title>{seo.title}</title>
      <meta name="description" content={seo.description} />
      <meta name="image" content={seo.image} />
      <meta name="keywords" content={seo.keywords} />

      <meta property="og:title" content={seo.title} />
      <meta property="og:image" content={seo.image} />
      <meta property="og:description" content={seo.description} />
      <meta property="og:url" content={seo.url} />
      <meta name="author" content={seo.author} />

      <link rel="icon" href="../images/favicon.ico" />
      {children}
    </>
  );
};
