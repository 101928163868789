import * as React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Layout from "../../components/layout";
import { graphql, Link } from "gatsby";
import { getImage } from "gatsby-plugin-image";
import Container from "react-bootstrap/Container";
import "./index.module.css";
import { GatsbyImage } from "gatsby-plugin-image";
import {
  blogPage,
  title,
  link,
  date,
  image,
  abstract,
  category,
  featuredRow,
} from "./index.module.css";
import { SEO } from "../../components/seo";

const BlogPage = ({ data }) => {
  return (
    <Layout pageTitle="Blog">
      {/* All posts */}
      <Container fluid className={blogPage}>
        {/* Featured */}
        <Row style={{ alignItems: "center" }} className={featuredRow}>
          <Col xs={12} sm={12} md={12} lg={11} xl={9}>
            <article key={data.id}>
              <Link to={`/blog/${data.mdx.frontmatter.slug}`} className={link}>
                <Row>
                  <Col md={5} lg={4}>
                    <GatsbyImage
                      image={getImage(
                        data.mdx.frontmatter.hero_image.childImageSharp
                      )}
                      className={image}
                      height={100}
                    />
                  </Col>
                  <Col md={7} lg={8}>
                    <p className={date}>{data.mdx.frontmatter.date}</p>
                    <p className={category}>{data.mdx.frontmatter.category}</p>
                    <h5 className={title}>{data.mdx.frontmatter.title}</h5>
                    <p className={abstract}>{data.mdx.frontmatter.abstract}</p>
                  </Col>
                </Row>
              </Link>
            </article>
          </Col>
        </Row>

        {/* All articles */}
        <Row>
          {data.allMdx.nodes.map((node) => (
            <Col sm={6} md={6} lg={3}>
              <article key={node.id}>
                <Link to={`/blog/${node.frontmatter.slug}`} className={link}>
                  <p className={date}>{node.frontmatter.date}</p>
                  <GatsbyImage
                    image={getImage(
                      node.frontmatter.hero_image.childImageSharp
                    )}
                    className={image}
                  />
                  <p className={category}>{node.frontmatter.category}</p>
                  <h5 className={title}>{node.frontmatter.title}</h5>
                  <p className={abstract}>{node.frontmatter.abstract}</p>
                </Link>
              </article>
            </Col>
          ))}
        </Row>
      </Container>
    </Layout>
  );
};

export const query = graphql`
  query {
    allMdx(
      sort: { fields: frontmatter___date, order: DESC }
      filter: { frontmatter: { type: { eq: "blog" } } }
    ) {
      nodes {
        frontmatter {
          author
          date(formatString: "MMM DD, Y")
          title
          abstract
          hero_image {
            childImageSharp {
              gatsbyImageData
            }
          }
          category
          slug
        }
        id
      }
    }
    mdx(frontmatter: { type: { eq: "featured" } }) {
      frontmatter {
        date(formatString: "MMM DD, Y")
        title
        abstract
        hero_image {
          childImageSharp {
            gatsbyImageData(height: 450)
          }
        }
        category
        slug
      }
      id
    }
  }
`;

export default BlogPage;

export const Head = () => (
  <SEO description="Explore our blog content and learn what you can do to help your yacht charter business" />
);
